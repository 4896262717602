exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-call-index-js": () => import("./../../../src/pages/book-call/index.js" /* webpackChunkName: "component---src-pages-book-call-index-js" */),
  "component---src-pages-client-policy-index-js": () => import("./../../../src/pages/client-policy/index.js" /* webpackChunkName: "component---src-pages-client-policy-index-js" */),
  "component---src-pages-complaint-form-index-js": () => import("./../../../src/pages/complaint-form/index.js" /* webpackChunkName: "component---src-pages-complaint-form-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-english-index-js": () => import("./../../../src/pages/english/index.js" /* webpackChunkName: "component---src-pages-english-index-js" */),
  "component---src-pages-english-intensive-school-index-js": () => import("./../../../src/pages/english/intensive-school/index.js" /* webpackChunkName: "component---src-pages-english-intensive-school-index-js" */),
  "component---src-pages-english-one-to-one-online-index-js": () => import("./../../../src/pages/english/one-to-one-online/index.js" /* webpackChunkName: "component---src-pages-english-one-to-one-online-index-js" */),
  "component---src-pages-english-one-to-one-school-index-js": () => import("./../../../src/pages/english/one-to-one-school/index.js" /* webpackChunkName: "component---src-pages-english-one-to-one-school-index-js" */),
  "component---src-pages-english-online-index-js": () => import("./../../../src/pages/english/online/index.js" /* webpackChunkName: "component---src-pages-english-online-index-js" */),
  "component---src-pages-english-saturday-courses-index-js": () => import("./../../../src/pages/english/saturday-courses/index.js" /* webpackChunkName: "component---src-pages-english-saturday-courses-index-js" */),
  "component---src-pages-english-test-index-js": () => import("./../../../src/pages/english-test/index.js" /* webpackChunkName: "component---src-pages-english-test-index-js" */),
  "component---src-pages-english-visa-study-work-index-js": () => import("./../../../src/pages/english/visa-study-work/index.js" /* webpackChunkName: "component---src-pages-english-visa-study-work-index-js" */),
  "component---src-pages-enquiry-form-index-js": () => import("./../../../src/pages/enquiry-form/index.js" /* webpackChunkName: "component---src-pages-enquiry-form-index-js" */),
  "component---src-pages-escape-app-index-js": () => import("./../../../src/pages/escape-app/index.js" /* webpackChunkName: "component---src-pages-escape-app-index-js" */),
  "component---src-pages-free-trial-lesson-index-js": () => import("./../../../src/pages/free-trial-lesson/index.js" /* webpackChunkName: "component---src-pages-free-trial-lesson-index-js" */),
  "component---src-pages-ibiza-experience-index-js": () => import("./../../../src/pages/ibiza-experience/index.js" /* webpackChunkName: "component---src-pages-ibiza-experience-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-online-index-js": () => import("./../../../src/pages/learn-online/index.js" /* webpackChunkName: "component---src-pages-learn-online-index-js" */),
  "component---src-pages-learn-school-index-js": () => import("./../../../src/pages/learn-school/index.js" /* webpackChunkName: "component---src-pages-learn-school-index-js" */),
  "component---src-pages-our-locations-index-js": () => import("./../../../src/pages/our-locations/index.js" /* webpackChunkName: "component---src-pages-our-locations-index-js" */),
  "component---src-pages-spanish-index-js": () => import("./../../../src/pages/spanish/index.js" /* webpackChunkName: "component---src-pages-spanish-index-js" */),
  "component---src-pages-spanish-online-index-js": () => import("./../../../src/pages/spanish/online/index.js" /* webpackChunkName: "component---src-pages-spanish-online-index-js" */),
  "component---src-pages-spanish-school-index-js": () => import("./../../../src/pages/spanish/school/index.js" /* webpackChunkName: "component---src-pages-spanish-school-index-js" */),
  "component---src-pages-start-learning-index-js": () => import("./../../../src/pages/start-learning/index.js" /* webpackChunkName: "component---src-pages-start-learning-index-js" */),
  "component---src-pages-terms-condition-index-js": () => import("./../../../src/pages/terms&condition/index.js" /* webpackChunkName: "component---src-pages-terms-condition-index-js" */)
}

