module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Escape Campus","short_name":"Escape Campus","description":"English Language Learning School","lang":"en","display":"standalone","icon":"src/assets/icons/logo.png","start_url":"/","background_color":"#ffffff","theme_color":"#219BD9","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79180b096dffbceffa1880841d7fb414"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
